import { TEXT_WITH_IMAGE_CE_NAME, TextWithImageContentElementProps } from "./textWithImageContentElement";

/**
 * This is the global style of a CE. 
 * This style is applied on all instances of this CE
 * 
 * @param props 
 * @returns 
 */
export default function TextWithImageContentElementStyleGlobal(props: TextWithImageContentElementProps) {
  return (
    <style jsx global>
      {`
        :global(.${TEXT_WITH_IMAGE_CE_NAME}) {
        }
      `}
    </style>
  );
}

import CmsImage from "@/components/cms/cmsImage/cmsImage";
import OptimizeStrapiImage, {
  OptimizeStrapiImageWidthLimits,
} from "@/components/cms/cmsImage/optimizeStrapiImage";
import { MediaSelectorOption } from "@/components/cms/cmsMediaSelector/cmsMediaSelectorDropdown";
import YoutubeIframe from "@/components/youtubeIframe/youtubeIframe";
import { useAppSelector } from "@/store/store";
import { StrapiUploadFile } from "@/types/strapi";
import { getNextJsApiURL } from "@/utils/api";
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_YOUTUBE,
} from "@/utils/constants";
import { renderImage } from "@/utils/imageUtil";
import { createDSGVOYoutubeUrl } from "@/utils/urlUtil";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";
import PbContentMediaStyleGlobal from "./pbContentMediaStyleGlobal";
import PbContentMediaStyleScoped from "./pbContentMediaStyleScoped";
const CmsContentMedia = dynamic(
  () => import("@/components/cms/cmsContent/cmsContentMedia")
);

export interface PbContentMediaProps {
  scopedSelector?: string;
  showButtonOverlayOnlyOnInit?: boolean;
  youtubeContent?: PbContentMediaYoutubeContent;
  imgContent?: PbContentMediaImgContent;
  videoContent?: PbContentMediaVideoContent;
  audioContent?: PbContentMediaAudioContent;
  disableEditView?: boolean;
  cmsPosition: number;
  mediaOptions: Array<MediaSelectorOption>;
  isMobile?: boolean;
  widthLimitSettings?: OptimizeStrapiImageWidthLimits | undefined;
}

export interface PbContentMediaImgContent {
  image: StrapiUploadFile;
  alt?: string;
  cmsFieldImage: string;
  cmsFieldAlt: string;
}

export interface PbContentMediaYoutubeContent {
  url: string;
  cmsFieldYoutubeUrl: string;
}

export interface PbContentMediaVideoContent {
  video: StrapiUploadFile;
  videoThumbnail: StrapiUploadFile;
  cmsFieldVideo: string;
  cmsFieldVideoThumbnail: string;
}

export interface PbContentMediaAudioContent {
  audio: StrapiUploadFile;
  cmsFieldAudio: string;
}

export default function PbContentMedia(props: PbContentMediaProps) {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const scopedSelector = `${props.scopedSelector}-content-media`;

  useEffect(
    () => setYoutubeUrl(createDSGVOYoutubeUrl(props.youtubeContent?.url || "")),
    [props.youtubeContent]
  );

  const selectedMediaOption = useRef<any>(null);
  if (props.imgContent?.image) {
    selectedMediaOption.current = MEDIA_TYPE_IMG;
  } else if (props.youtubeContent?.url) {
    selectedMediaOption.current = MEDIA_TYPE_YOUTUBE;
  } else if (props.videoContent?.video) {
    selectedMediaOption.current = MEDIA_TYPE_VIDEO;
  } else if (props.audioContent?.audio) {
    selectedMediaOption.current = MEDIA_TYPE_AUDIO;
  } else {
    selectedMediaOption.current = null;
  }

  return (
    <>
      {editView && !props.disableEditView ? (
        <CmsContentMedia
          {...props}
          selectedMediaOption={selectedMediaOption.current}
          scopedSelector={scopedSelector}
          youtubeUrl={youtubeUrl}
        />
      ) : (
        <div className={`multimedia-wrapper ${scopedSelector}`}>
          {/* image */}
          {selectedMediaOption.current === MEDIA_TYPE_IMG &&
            props.imgContent?.image?.mime && (
              <OptimizeStrapiImage
                image={props.imgContent?.image}
                isMobile={props.isMobile || false}
                widthLimitSettings={props.widthLimitSettings}
              >
                <CmsImage
                  image={props.imgContent?.image}
                  objectPosition="50%"
                  className="mx-auto h-auto w-100"
                />
              </OptimizeStrapiImage>
            )}
          {/* youtube */}
          {selectedMediaOption.current === MEDIA_TYPE_YOUTUBE && (
            <div className="cms-content-media-youtube-container mx-auto">
              <div className="cms-content-media-youtube">
                <YoutubeIframe url={youtubeUrl} />
              </div>
            </div>
          )}
          {/* video */}
          {selectedMediaOption.current === MEDIA_TYPE_VIDEO &&
            props.videoContent?.video?.mime === "video/mp4" && (
              <video
                className="cms-content-media-video d-block mx-auto"
                id={`cms-content-media-video-${props.cmsPosition}`}
                controls
                muted
                preload="none"
                poster={renderImage(props.videoContent?.videoThumbnail, true)}
              >
                <source
                  src={getNextJsApiURL(
                    `${props.videoContent?.video?.url}?mediaType=${MEDIA_TYPE_VIDEO}`
                  )}
                  type="video/mp4"
                />
              </video>
            )}
          {/* audio */}
          {selectedMediaOption.current === MEDIA_TYPE_AUDIO &&
            props.audioContent?.audio?.id && (
              <audio
                className="cms-content-media-audio d-block mx-auto"
                controls
                preload="none"
                src={getNextJsApiURL(
                  `${props.audioContent?.audio?.url}?mediaType=${MEDIA_TYPE_AUDIO}`
                )}
              />
            )}
        </div>
      )}
      <PbContentMediaStyleGlobal />
      <PbContentMediaStyleScoped scopedSelector={scopedSelector} />
    </>
  );
}

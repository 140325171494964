export default function PbContentMediaStyleGlobal() {
  return (
    <style jsx global>{`
      :global(.multimedia-wrapper) {
        .cms-content-media-audio {
          max-width: 100%;
          width: 600px;
        }
      }
    `}</style>
  );
}

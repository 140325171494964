import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { getObjectFitValue } from "@/utils/imageUtil";
import {
  getCssMaxWidthValue,
  lowerBreakpointDesktopPx,
  lowerBreakpointMobilePx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import { TextWithImageContentElementProps } from "./textWithImageContentElement";

interface TextWithImageContentElementStyleScopedProps
  extends TextWithImageContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export default function TextWithImageContentElementStyleScoped(
  props: TextWithImageContentElementStyleScopedProps
) {
  const maxWidthImage = {
    mobile: getCssMaxWidthValue(
      props.ceSettings?.maxWidthImage.mobile,
      props.content.cfgImageIgnoresMaxWidth
    ),
    tablet: getCssMaxWidthValue(
      props.ceSettings?.maxWidthImage.tablet,
      props.content.cfgImageIgnoresMaxWidth
    ),
    desktop: getCssMaxWidthValue(
      props.ceSettings?.maxWidthImage.desktop,
      props.content.cfgImageIgnoresMaxWidth
    ),
    wqhd: getCssMaxWidthValue(
      props.ceSettings?.maxWidthImage.wqhd,
      props.content.cfgImageIgnoresMaxWidth
    ),
  };

  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        .dropzone-preview {
          max-height: none !important;
        }

        .half-spacer-middle-right,
        .half-spacer-middle-left,
        .half-spacer-middle-top,
        .half-spacer-middle-bottom {
          padding-top: ${(props.ceSettings?.spacerMiddle / 2) *
            (globalConfig?.layout.scalingfactorSpaceX.mobile ?? 1) +
          "px"};
          padding-bottom: ${(props.ceSettings?.spacerMiddle / 2) *
            (globalConfig?.layout.scalingfactorSpaceX.mobile ?? 1) +
          "px"};
        }

        .image-col .cms-image {
          max-height: ${props.ceSettings?.maxHeightImage?.mobile}px !important;
          // Image only: max-width
          max-width: ${maxWidthImage.mobile} !important;
          @media (min-width: ${lowerBreakpointMobilePx()}) {
            max-height: ${props.ceSettings?.maxHeightImage
              ?.tablet}px !important;
            // Image only: max-width
            max-width: ${maxWidthImage.tablet} !important;
          }
          @media (min-width: ${lowerBreakpointDesktopPx()}) {
            max-height: ${props.ceSettings?.maxHeightImage
              ?.desktop}px !important;
            // Image only: max-width
            max-width: ${maxWidthImage.desktop} !important;
          }
          @media (min-width: ${lowerBreakpointWqhdPx()}) {
            max-height: ${props.ceSettings?.maxHeightImage?.wqhd}px !important;
            // Image only: max-width
            max-width: ${maxWidthImage.wqhd} !important;
          }
        }

        .image-col .preview-image {
          height: ${props.ceSettings?.maxHeightImage?.mobile}px !important;
          @media (min-width: ${lowerBreakpointMobilePx()}) {
            height: ${props.ceSettings?.maxHeightImage?.tablet}px !important;
          }
          @media (min-width: ${lowerBreakpointDesktopPx()}) {
            height: ${props.ceSettings?.maxHeightImage?.desktop}px !important;
          }
          @media (min-width: ${lowerBreakpointWqhdPx()}) {
            height: ${props.ceSettings?.maxHeightImage?.wqhd}px !important;
          }
        }
        .image-col iframe {
          height: 100%;
          width: 100%;
          aspect-ratio: 16 / 9;
          max-width: 100%;

          max-height: ${props.ceSettings?.maxHeightImage?.mobile}px !important;
          @media (min-width: ${lowerBreakpointMobilePx()}) {
            max-height: ${props.ceSettings?.maxHeightImage
              ?.tablet}px !important;
          }
          @media (min-width: ${lowerBreakpointDesktopPx()}) {
            max-height: ${props.ceSettings?.maxHeightImage
              ?.desktop}px !important;
          }
          @media (min-width: ${lowerBreakpointWqhdPx()}) {
            max-height: ${props.ceSettings?.maxHeightImage?.wqhd}px !important;
          }
        }

        .cms-image,
        .cms-content-media-youtube-container {
          object-fit: ${getObjectFitValue(
            props.content.cfgImageObjectFit
          )} !important;
        }

        .richtext-col,
        .button-col {
          margin-top: ${props.ceSettings?.spacerHeadlineRichtext
            ? props.ceSettings?.spacerHeadlineRichtext + "px"
            : "0"};
          margin-bottom: ${props.ceSettings?.spacerButton
            ? props.ceSettings?.spacerButton + "px"
            : "5px"};
        }

        @media (min-width: ${lowerBreakpointTabletPx()}) {
          .half-spacer-middle-bottom {
            padding-top: ${props.ceSettings?.spacerMiddle / 2}px;
            padding-bottom: 0;
          }
          .half-spacer-middle-top {
            padding-bottom: ${props.ceSettings?.spacerMiddle / 2}px;
            padding-top: 0;
          }
          .half-spacer-middle-right {
            padding-left: ${props.ceSettings?.spacerMiddle / 2}px;
            padding-top: 0;
            padding-bottom: 0;
          }
          .half-spacer-middle-left {
            padding-right: ${props.ceSettings?.spacerMiddle / 2}px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
        .vertical-aligned {
          height: 100%;
          margin: auto;
        }
      }
    `}</style>
  );
}

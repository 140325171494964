import PbContentButton from "@/components/content/pbContentButton/pbContentButton";
import PbContentHeading from "@/components/content/pbContentHeading/pbContentHeading";
import PbContentMedia from "@/components/content/pbContentMedia/pbContentMedia";
import PbContentRichText from "@/components/content/pbContentRichText/pbContentRichText";
import TextWithImageContentElementStyleScoped from "@/components/contentelements/textWithImageContentElement/textWithImageContentElementStyleScoped";
import useCesStrButton from "@/hooks/useCesStrButton";
import useCesStrHeadline from "@/hooks/useCesStrHeadline";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScopedClassName from "@/hooks/useScopedClassName";
import { valueFromStoreSetting } from "@/services/ceSettings/ceSettingsService";
import { useAppSelector } from "@/store/store";
import { ContentElementTextWithImageStoreSetting } from "@/types/ceSettings/ceSettings";
import { HeadlineHeading } from "@/types/config/enums";
import { CETextWithImage } from "@/types/content-elements";
import { MEDIA_TYPE_IMG, MEDIA_TYPE_YOUTUBE } from "@/utils/constants";
import { getPbContentScopedSelector } from "@/utils/util";
import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import TextWithImageContentElementStyleGlobal from "./textWithImageContentElementStyleGlobal";

export interface TextWithImageContentElementProps {
  content: CETextWithImage;
  position: number;
  ceSettings: ContentElementTextWithImageStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const TEXT_WITH_IMAGE_CE_NAME = "textwithimage_CE";

/**
 * Component that contains a richtext with extra headline,
 * a button with a link and an image
 */
const TextWithImageContentElement = (
  props: TextWithImageContentElementProps
) => {
  const TEXT_WITH_IMAGE_SCOPED_CLASS = useScopedClassName(
    TEXT_WITH_IMAGE_CE_NAME,
    props.content,
    props.position
  );
  const tCms = useCmsTranslation();

  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const headlineSettings = useCesStrHeadline({
    typography: props.content.cfgStrInnerHeadlineTypography,
    font: props.content.cfgStrInnerHeadlineFont,
    fontColor: props.content.cfgStrInnerHeadlineFontColor,
    cfgHeadlineType: props.content.cfgHeadlineType as HeadlineHeading,
    ceSettingHeadline: props.ceSettings?.headlineheading,
    cfgHeadlineStyle: props.content.cfgHeadlineStyle as HeadlineHeading,
    ceSettings: props.ceSettings.headline,
  });

  const { buttonClassName } = useCesStrButton(
    props.content.cfgStrInnerButtonButton,
    props.ceSettings.button)

  const { richTextClassName, richtextEditorSettings } = useCesStrRichText(
    props.ceSettings?.richtext
  );
  const textToImageRatioValue = valueFromStoreSetting(
    props.content.cfgStrTextToImageRatio
  )?.toString();

  const textToImageRatio = (
    textToImageRatioValue && textToImageRatioValue !== "default"
      ? textToImageRatioValue
      : "6/6"
  ).split("/");

  const textCol = +textToImageRatio[0];
  const imageCol = +textToImageRatio[1];

  const imageTop = props.content.cfgImageTopInsteadOfRight;
  const imageLeft = props.content.cfgImageLeft;
  const centerVertically = !props.content.cfgPositionImageOrTextAtTop;

  return (
    <>
      <ContentElementWrapper
        scopedClassName={TEXT_WITH_IMAGE_SCOPED_CLASS}
        name={TEXT_WITH_IMAGE_CE_NAME}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <Col
          xs={12}
          className={clsx(
            TEXT_WITH_IMAGE_CE_NAME,
            TEXT_WITH_IMAGE_SCOPED_CLASS
          )}
        >
          <Row className={`g-0`}>
            <Col
              xs={12}
              md={props.content.cfgImageTopInsteadOfRight ? 12 : textCol}
              className={clsx(
                "text-col order-md-2",
                !imageTop && imageLeft && "half-spacer-middle-right",
                !imageTop && !imageLeft && "half-spacer-middle-left",
                imageTop && imageLeft && "half-spacer-middle-bottom",
                imageTop && !imageLeft && "half-spacer-middle-top",
                centerVertically && "vertical-aligned",
                !centerVertically && "not-vertical-aligned"
              )}
            >
              <Row className="edge-to-edge-row">
                <Col xs={12} className="heading-col s-pb-1">
                  <PbContentHeading
                    scopedSelector={getPbContentScopedSelector(
                      TEXT_WITH_IMAGE_SCOPED_CLASS
                    )}
                    content={props.content.title}
                    cmsPosition={props.position}
                    cmsField="title"
                    settings={{
                      ...headlineSettings,
                    }}
                    cmsPlaceholder={tCms("title")}
                  />
                </Col>
              </Row>
              <Row className="edge-to-edge-row">
                <Col xs={12} className="richtext-col">
                  <PbContentRichText
                    className={richTextClassName}
                    content={props.content.richTextContent}
                    position={props.position}
                    ceSettings={richtextEditorSettings}
                    cmsFieldContent="richTextContent"
                  />
                </Col>
              </Row>
              {editView || (props.content.btnUrl && props.content.btnText) ? (
                <Row className="edge-to-edge-row">
                  <Col xs={12} className="button-col">
                    <PbContentButton
                      popoverPlacement={props.position < 1 ? "right" : "top"}
                      text={props.content.btnText}
                      url={props.content.btnUrl}
                      rel={props.content.btnRel}
                      target={props.content.btnTarget}
                      getParams={props.content.btnGetParams}
                      hrefLang={props.content.btnHrefLang}
                      anchorName={props.content.btnAnchorName}
                      className={buttonClassName}
                      cmsPosition={props.position}
                      cmsFieldText="btnText"
                      cmsFieldUrl="btnUrl"
                      cmsFieldRel="btnRel"
                      cmsFieldTarget="btnTarget"
                      cmsFieldGetParams="btnGetParams"
                      cmsFieldHrefLang="btnHrefLang"
                      cmsFieldAnchorName="btnAnchorName"
                      cmsPopoverOpenerId={`content${props.position}.btn`}
                    />
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col
              xs={12}
              md={props.content.cfgImageTopInsteadOfRight ? 12 : imageCol}
              className={clsx(
                "image-col",
                `order-md-${props.content.cfgImageLeft ? "1" : "3"}`,
                !imageTop && imageLeft && "half-spacer-middle-left",
                !imageTop && !imageLeft && "half-spacer-middle-right",
                imageTop && imageLeft && "half-spacer-middle-top",
                imageTop && !imageLeft && "half-spacer-middle-bottom",
                centerVertically && "vertical-aligned",
                !centerVertically && "not-vertical-aligned"
              )}
            >
              <PbContentMedia
                scopedSelector={getPbContentScopedSelector(
                  TEXT_WITH_IMAGE_SCOPED_CLASS
                )}
                isMobile={props.isMobile}
                cmsPosition={props.position}
                mediaOptions={[MEDIA_TYPE_IMG, MEDIA_TYPE_YOUTUBE]}
                imgContent={{
                  image: props.content.img,
                  cmsFieldImage: "img",
                  alt: props.content.img?.alternativeText,
                  cmsFieldAlt: "alt",
                }}
                youtubeContent={{
                  url: props.content.youtubeUrl,
                  cmsFieldYoutubeUrl: "youtubeUrl",
                }}
                widthLimitSettings={
                  props.content.cfgImageIgnoresMaxWidth
                    ? undefined
                    : props.ceSettings?.maxWidthImage
                }
              />
            </Col>
          </Row>
        </Col>
      </ContentElementWrapper>
      <TextWithImageContentElementStyleGlobal {...props} />
      <TextWithImageContentElementStyleScoped
        {...props}
        scopedSelector={TEXT_WITH_IMAGE_SCOPED_CLASS}
      />
    </>
  );
};

export default React.memo(TextWithImageContentElement);

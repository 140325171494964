import useCmsTranslation from "@/hooks/useCmsTranslation";

interface YoutubeIframeProps {
  url: string;
  id?: string;
  className?: string;
}

/**
 * Youtube Iframe
 */
export default function YoutubeIframe(props: YoutubeIframeProps) {
  const tCms = useCmsTranslation();

  return (
    <iframe
      src={props.url}
      id={props.id}
      title={tCms("youtubePlayer")}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className={props.className || ""}
    ></iframe>
  );
}

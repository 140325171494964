interface PbContentMediaStyleScopedProps {
  scopedSelector: string;
}

export default function PbContentMediaStyleScoped(
  props: PbContentMediaStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
      }
    `}</style>
  );
}
